import React from 'react'
import MenuDesktop from 'components/MenuDesktop'
import LinkWrap from 'components/LinkWrap'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'
import { StaticImage } from 'gatsby-plugin-image'

const Component = ({ layoutProps }) => {
  const { isHeaderUp, toggleIsMenuOpen } = layoutProps
  const headerUpClass = isHeaderUp ? styles.headerUp : ''
  return (
    <Inview className={`${styles.mainHeader} ${headerUpClass}`}>
      <nav className={`${styles.mainNav} hasanimation in in-fade-in`}>
        <a
          className={styles.mobileHamburgerElectronic}
          onClick={toggleIsMenuOpen}
        >
          <i className='fal fa-bars'></i>
        </a>
        <div className={styles.navLogo}>
          <LinkWrap to='/' className={styles.image}>
            <StaticImage
              src='../../assets/images/logo-intelligent-suspension-platinum.png'
              alt=''
            />
          </LinkWrap>
        </div>
        <MenuDesktop />
      </nav>
    </Inview>
  )
}

export default Component
