import React from 'react'
import { LocalizedLink as Link, useLocalization } from 'gatsby-theme-i18n'

const LinkWrap = ({ to, className = '', activeClassName = '', children }) => {
  const { locale } = useLocalization()
  if (to) {
    let setTo = to
    if (to.endsWith('/') && to !== '/') {
      setTo = to.slice(0, to.length - 1)
    }
    if (to === '/' && locale === 'en') {
      setTo = '/en'
    }
    return (
      <Link
        to={setTo}
        className={className}
        activeClassName={activeClassName}
        partiallyActive={to !== '/'}
      >
        {children}
      </Link>
    )
  } else {
    return <a className={`empty ${className}`}>{children}</a>
  }
}

export default LinkWrap
