import React from 'react'
import { StaticQuery, graphql, navigate } from 'gatsby'
import LinkWrap from 'components/LinkWrap'
import { Link } from 'gatsby'
import Inview from 'components/Inview'
import { LocaleContext } from 'gatsby-theme-i18n'
import * as styles from './styles.module.scss'
import { StaticImage } from 'gatsby-plugin-image'

const Component = ({ data, layoutProps }) => {
  const locale = React.useContext(LocaleContext)
  const { isMenuOpen, toggleIsMenuOpen } = layoutProps
  const menuOpenClass = isMenuOpen ? styles.menuOpen : ''

  let menu = data.menus.nodes[0].frontmatter[locale]

  let pages = data.pages.nodes
  const pagesLocale = pages.map((page) => page.frontmatter[locale])
  menu.main.forEach((item) => {
    item.data = pagesLocale.find((page) => page.slug === item.page)
    item.submenu?.forEach((subitem) => {
      subitem.data = pages.find((page) => page.slug === subitem.page)
    })
  })

  return (
    <div className={`${styles.mobileNavElectronic} ${menuOpenClass}`}>
      <nav>
        <ul>
          <li className={styles.closeMenu}>
            <a onClick={toggleIsMenuOpen}>
              <i className='fal fa-times'></i>
            </a>
          </li>
          {menu.main.map((item, i) => (
            <M0 content={item} key={i} isLast={i === menu.main.length - 1} />
          ))}
          <li className={styles.lanHolder}>
            <Link className={styles.lanLink} to='/en'>
              <StaticImage src='../../assets/images/english.png' alt='' />
            </Link>
            <Link className={styles.lanLink} to='/'>
              <StaticImage src='../../assets/images/china.png' alt='' />
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  )
}

const M0 = ({ content, isLast }) => {
  const { type, data, text, url } = content
  const isLastClass = isLast ? styles.isLast : ''
  if (type === 'PageLink') {
    return (
      <li className={isLastClass}>
        <LinkWrap to={data.path}>{data.title}</LinkWrap>
      </li>
    )
  }
  if (type === 'ExternalLink') {
    return (
      <li className={isLastClass}>
        <a href={url} target='_blank'>
          {text}
        </a>
      </li>
    )
  }
  return <div />
}

export default function Data({ layoutProps }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          menus: allMarkdownRemark(
            filter: {
              frontmatter: {
                en: { templateKey: { eq: "menu" }, slug: { eq: "primary" } }
              }
            }
          ) {
            nodes {
              frontmatter {
                en {
                  title
                  main {
                    type
                    page
                    text
                    url
                  }
                }
                zh {
                  title
                  main {
                    type
                    page
                    text
                    url
                  }
                }
              }
            }
          }
          pages: allMarkdownRemark(
            filter: { frontmatter: { en: { templateKey: { eq: "page" } } } }
          ) {
            nodes {
              frontmatter {
                en {
                  slug
                  title
                  path
                }
                zh {
                  slug
                  title
                  path
                }
              }
            }
          }
        }
      `}
      render={(data) => <Component data={data} layoutProps={layoutProps} />}
    />
  )
}

// submenu {
//   page
// }
